import React, { useState } from 'react'
import axios from 'axios'
import styled from 'styled-components'

import { Flex, Box } from 'reflexbox'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Heading from '../components/Heading/Heading'
import Prefooter from '../components/PreFooter/Prefooter'
import Footer from '../components/Footer/Footer'
import Boutton from '../components/Boutton/Boutton'
import Center from '../components/Center'

const Styles = styled.div`
  padding-top: 4vh;

  padding: 20px;

  h1 {
    border-bottom: 1px solid white;
    color: #3d3d3d;
    font-family: sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    padding: 10px;
    text-align: center;
  }

  form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    margin: 0 auto;
    max-width: 500px;
    padding: 30px 50px;
  }

  input {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 10px;
    width: 100%;
  }

  label {
    color: #3d3d3d;
    display: block;
    font-family: sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }

  .error {
    color: red;
    font-family: sans-serif;
    font-size: 12px;
    height: 30px;
  }

  .submitButton {
    background-color: #6976d9;
    color: white;
    font-family: sans-serif;
    font-size: 14px;
    margin: 20px 0px;
  }
`

const Contact = () => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  })
  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    })
    if (ok) {
      form.reset()
    }
  }
  const handleOnSubmit = e => {
    e.preventDefault()
    const form = e.target
    setServerState({ submitting: true })
    axios({
      method: 'post',
      url: 'https://getform.io/f/4ebf083f-9259-4c7b-84ad-dede5b84dc9e',
      data: new FormData(form),
    })
      .then(r => {
        handleServerResponse(true, 'Thanks!', form)
      })
      .catch(r => {
        handleServerResponse(false, r.response.data.error, form)
      })
  }
  return (
    <Layout>
      <Seo
        title="Destruction nid guêpes frelons chenilles processionnaires 77 91 94 Melun Créteil Evry"
        encodeSpecialCharacters
        defer={false}
        description="ABC Guêpes Destruction de nid de guêpes, de frelons, d'insectes volants ...Traitement contre les chenilles processionnaires. Seine et Marne 77 Essonne 91 Yonne 89 Melun Montereau Nangis Provins Nemours Chelles Fontainebleau Sens Corbeil Essonnes Evry"
        keywords="ABC Guêpes Destruction de nid de guêpes, de frelons, d'insectes volants ...Traitement contre les chenilles processionnaires. Seine et Marne 77 Essonne 91 Yonne 89 Melun Montereau Nangis Provins Nemours Chelles Fontainebleau Sens Corbeil Essonnes Evry"
      />
      <Heading />
      <Flex flexWrap="wrap">
        <Box width={[1, 1]} py={[2, 3, 5]}>
          <Styles>
            <div>
              <div className="col-md-8 mt-5">
                <form onSubmit={handleOnSubmit}>
                  <label>
                    Nom
                    <input type="text" name="name" />
                  </label>
                  <label>
                    Prénom
                    <input type="text" name="surname" />
                  </label>
                  <label>
                    Email
                    <input type="email" name="email" />
                  </label>

                  <label>
                    Message
                    <input type="text" name="message" />
                  </label>

                  <Center>
                    <Boutton type="submit">Envoyer</Boutton>
                  </Center>
                </form>
              </div>
            </div>
          </Styles>
        </Box>
      </Flex>

      <Prefooter />
      <Footer />
    </Layout>
  )
}

export default Contact
